import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { updateMathJax } from "../../..";
import {
  CalcTable,
  CalcTypeToParse,
  Canvas,
  FigureBase,
  InputVariable,
} from "../../../commonTypes/CalculationRunTypes";
import { PublicCalcName } from "../../../commonTypes/CalculationT";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  calculationActions,
  getCalculationRunResults,
  getCalculationRunStatus,
} from "../../../reduxSlices/public-calc";
import { routes } from "../../../routes";
import CalculationInputTable, { FormValuesT, TableFormValuesT } from "../../CalculationInputTable";
import CalculationResultsView from "../../CalculationResultsView";
import CanvasDesigner from "../../CanvasDesigner";
import FigureDesigner from "../../FigureDesigner";
import { PmmData } from "../../plotly-render/PmmData";
import ResultActionButtons from "../../ResultActionButtons";
import {
  getLocalInputs,
  getLocalInputTables,
  saveLocalInputs,
  saveLocalInputTables,
} from "../utils";
import DesignSectionSkeleton from "./InputOutputDesignerSkeleton";

type Props = {
  calcName: PublicCalcName;
};

export default function InputOutputDesigner({ calcName }: Props) {
  const dispatch = useAppDispatch();
  const currentRun = useAppSelector(getCalculationRunResults);
  const currentRunStatus = useAppSelector(getCalculationRunStatus);

  const [inputItems, setInputItems] = useState<InputVariable[]>([]);
  const [inputTables, setInputTables] = useState<CalcTable[]>([]);
  const [inputFigures, setInputFigures] = useState<JSX.Element[]>([]);
  const [inputPmm, setInputPmm] = useState<PmmData[]>([]);
  const [resultItems, setResultItems] = useState<CalcTypeToParse[]>([]);
  const [inputs, setInputs] = useState<FormValuesT>(getLocalInputs(calcName));
  const [inputChanged, setInputChanged] = useState(false);

  const updateResults = (initialTableInputs?: TableFormValuesT) => {
    const tableInputs: TableFormValuesT =
      initialTableInputs ||
      inputTables.reduce<TableFormValuesT>(
        (prev, cur) => ({ ...prev, [cur.name ?? "input-table"]: cur.data || [[]] }),
        {}
      );
    dispatch(
      calculationActions.runPublicCalculation({
        name: calcName,
        inputs: { ...inputs, ...tableInputs },
      })
    );
    saveLocalInputTables(calcName, tableInputs);
    saveLocalInputs(calcName, inputs);
    setInputChanged(false);
  };

  // initial fetch of inputs and defaults
  useEffect(() => {
    updateResults(getLocalInputTables(calcName));
  }, []);

  // update mathjax whenever math containing items change
  useEffect(() => {
    updateMathJax();
  }, [inputItems, resultItems]);

  useEffect(() => {
    const inputItemList: InputVariable[] = [];
    const inputTableList: CalcTable[] = [];
    const inputFigureList: JSX.Element[] = [];
    const inputPmmList: PmmData[] = [];
    const resultItemList: CalcTypeToParse[] = [];

    currentRun?.items.forEach((item, index) => {
      switch (item.type) {
        case "Input":
          inputItemList.push(item as InputVariable);
          break;
        case "InputTable":
          inputTableList.push(item as CalcTable);
          break;
        case "Canvas":
          const canvas = item as Canvas;
          if (canvas.displayType === "report-input")
            inputFigureList.push(<CanvasDesigner item={canvas} key={index} />);
          if (canvas.displayType === "report-result") resultItemList.push(item);
          break;
        case "FigureBase":
          const figure = item as FigureBase;
          if (figure.displayType === "report-input")
            inputFigureList.push(<FigureDesigner item={figure} key={index} />);
          if (figure.displayType === "report-result") resultItemList.push(item);
          break;
        case "Calculation":
        case "Comparison":
        case "ComparisonForced":
        case "Symbolic":
        case "Table":
          if (!!item.finalResult) resultItemList.push(item);
          break;
        case "concrete-column-pmm":
          inputPmmList.push(item as PmmData);
          break;
        default:
          break;
      }
    });
    setInputItems(inputItemList);
    setInputTables(inputTableList);
    setInputFigures(inputFigureList);
    setInputPmm(inputPmmList);
    setResultItems(resultItemList);
  }, [currentRun]);

  const resultActionButtons = (
    <ResultActionButtons
      pathToReportPage={routes.publicCalculationReport.path(calcName)}
      updateButtonText="Refresh results."
      updateResults={() => updateResults()}
      isResultStale={inputChanged}
      isRunLoading={currentRunStatus === "loading"}
      runResults={currentRun?.items}
    />
  );

  return (
    <>
      {currentRun?.name !== calcName && currentRunStatus !== "failed" ? (
        <DesignSectionSkeleton />
      ) : (
        <Stack direction={{ xs: "column", md: "row" }} spacing={4} justifyContent="center">
          <CalculationInputTable
            inputItems={inputItems}
            onUpdateInputVals={() => setInputChanged(true)}
            updatedInputState={[inputs, setInputs]}
            inputTableState={[inputTables, setInputTables]}
            inputFigures={inputFigures}
          />
          <CalculationResultsView
            resultActionButtons={resultActionButtons}
            resultItems={resultItems}
            plotlyData={inputPmm}
            isResultStale={inputChanged}
            isLoading={currentRunStatus === "loading"}
          />
        </Stack>
      )}
    </>
  );
}
