import { Container, Stack } from "@mui/material";
import RelatedCalculationTool, { RelatedToolType } from "./RelatedCalculationTool";
import { P, Subtitle } from "./copy-utils";

export type RelatedTool = {
  calcName: RelatedToolType;
  text: string;
};

type Props = {
  relatedTools: RelatedTool[];
};

export default function RelatedCalcToolSection({ relatedTools }: Props) {
  return (
    <Container maxWidth="md" sx={{ marginBlock: 4, marginTop: 0 }}>
      <Subtitle variant="h2" gutterBottom>
        Other tools:
      </Subtitle>
      <P>Take a look at some of these similar free tools we've built. Hope you find them useful!</P>

      <Stack
        spacing={{ xs: 2, sm: 4 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        justifyContent="space-evenly"
      >
        {relatedTools.map((tool) => (
          <RelatedCalculationTool {...tool} key={`related-tool-${tool.calcName}`} />
        ))}
      </Stack>
    </Container>
  );
}
