import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet";
import { PublicCalcName } from "../../commonTypes/CalculationT";
import CloneOrCopyCode from "./common/CloneOrCopyCode";
import DesignSection from "./common/DesignSection";
import IntroSection from "./common/IntroSection";
import RelatedCalcToolSection, { RelatedTool } from "./common/RelatedCalcToolSection";
import { Disclaimer, Image, P, Subtitle } from "./common/copy-utils";

const CALC_TITLE = "Efficient Steel Beam Design Calculator";
export const CALC_NAME: PublicCalcName = "steel-beam-optimizer";

function HeadTags() {
  return (
    <Helmet>
      <title>{CALC_TITLE} | efficalc</title>
      <meta
        name="description"
        content="Easily design your steel beam with our free online capacity calculator. Quickly and accurately calculate the design moment strength with a detailed calculation report. Try it now for free!"
      />
      <meta property="og:title" content={`${CALC_TITLE} | efficalc`} />
      <meta
        property="og:url"
        content="https://efficalc.com/public-calc/design/steel-beam-optimizer"
      />
      <meta
        property="og:image"
        content="https://efficalc.com/images/public-calcs/wf_beam_optimizer_bkg.jpg"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="A free online design tool to quickly and accurately find an efficient steel wide flange beam for your given moment demand and beam span."
      />
    </Helmet>
  );
}

function Introduction() {
  const Description = (
    <>
      <P>
        Effortlessly design steel wide flange beams with this streamlined tool from efficalc. Simply
        input your moment demand and beam span, and the powerful calculator will select an efficient
        efficient beam size for your needs. Instantly receive a free detailed report explaining the
        moment capacity calculation for the selected beam, ensuring a quick and verifiable design
        process.
      </P>
      <Image
        src="/images/public-calcs/wf_beam_optimizer.jpg"
        alt="Beam section selection and deflected profile"
      ></Image>
    </>
  );

  const instantResultsText =
    "Simply enter the load, span, and material properties to receive an efficient beam size with an immediate and accurate design strength evaluation.";

  return (
    <IntroSection
      title={CALC_TITLE}
      descriptionSection={Description}
      instantResultsText={instantResultsText}
    />
  );
}

function DesignIntro() {
  return (
    <Container maxWidth="md">
      <Subtitle variant="h2" gutterBottom>
        Design your beam here:
      </Subtitle>
      <P>
        Use the interactive form below to find an efficient steel beam design. View or print the
        calculation report using the icons in the "Results" section.
      </P>
    </Container>
  );
}

const relatedTools: RelatedTool[] = [
  {
    calcName: "steel-beam-calculator",
    text: "Choose your own section size to get the design strength with the same calculation report as this tool.",
  },
  {
    calcName: "truss-tool",
    text: "A free online design tool to quickly and accurately calculate axial forces in truss members and global truss deflection.",
  },
];

export default function EfficientSteelBeamPage() {
  return (
    <Container maxWidth="xl">
      <HeadTags />

      <Stack marginY="1rem">
        <Introduction />

        <DesignIntro />
        <DesignSection calcName={CALC_NAME} />
        <CloneOrCopyCode calcName={CALC_NAME} />
        <RelatedCalcToolSection relatedTools={relatedTools} />
      </Stack>

      <Disclaimer />
    </Container>
  );
}
