import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet";
import { PublicCalcName } from "../../commonTypes/CalculationT";
import CloneOrCopyCode from "./common/CloneOrCopyCode";
import DesignSection from "./common/DesignSection";
import { FeatureSection, TitleSection } from "./common/IntroSection";
import RelatedCalcToolSection, { RelatedTool } from "./common/RelatedCalcToolSection";
import { Disclaimer, Image, LI, P, Subtitle } from "./common/copy-utils";

const CALC_TITLE = "Concrete Column PMM Designer";
export const CALC_NAME: PublicCalcName = "concrete-column-pmm";

const Description = (
  <>
    <P>
      Easily design concrete columns for biaxial bending with this advanced tool from efficalc.
      Define your column geometry and load cases, then the tool will generate a detailed 3D PMM
      plot, demand-to-capacity ratios, and a comprehensive report featuring 2D P-M plots for each
      load case.
    </P>
    <Image
      src="/images/public-calcs/conc_col_pmm.jpg"
      alt="Concrete column in biaxial bending with P-M diagram"
    ></Image>
  </>
);

function HeadTags() {
  return (
    <Helmet>
      <title>{CALC_TITLE} | efficalc</title>
      <meta
        name="description"
        content="Easily design your concrete column with our free online PMM designer. Quickly and accurately get load case PM diagrams and a detailed calculation report. Try it now for free!"
      />
      <meta property="og:title" content={`${CALC_TITLE} | efficalc`} />
      <meta
        property="og:url"
        content="https://efficalc.com/public-calc/design/concrete-column-pmm"
      />
      <meta
        property="og:image"
        content="https://efficalc.com/images/public-calcs/conc_col_pmm_bkg.jpg"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="A free online design tool to quickly and accurately design a concrete column subjected to biaxial bending."
      />
    </Helmet>
  );
}

function DesignIntro() {
  return (
    <Container maxWidth="md">
      <Subtitle variant="h2" gutterBottom>
        Design your column here:
      </Subtitle>
      <P>
        Use the interactive form below to design your column for bi-directional flexure. View or
        print the calculation report using the icons in the "Results" section.
      </P>
    </Container>
  );
}

const relatedTools: RelatedTool[] = [
  {
    calcName: "steel-beam-calculator",
    text: "Quickly and accurately design steel wide flange beam for your given moment demand and beam span.",
  },
  {
    calcName: "concrete-beam-tool",
    text: "Calculate the capacity of a doubly or singly reinforced concrete beam.",
  },
];

const howItWorksListItems = [
  <LI>
    <b>Capacity Calculation:</b> Column capacity is calculated by the exact capacity method. In
    contrast to approximate methods such as the Bresler load contour method and the Bresler
    reciprocal load method, the exact capacity method calculates the column’s axial and moment
    capacity at the particular eccentricity angle at which the load is applied.
  </LI>,
  <LI>
    <b>PMM Diagrams:</b> The PMM surface describes the capacity of the column in P, Mx, My (any load
    case falling inside the PMM surface is within capacity, and any load case falling outside the
    PMM surface exceeds capacity). The PMM diagrams created by this program comprise a mesh of
    calculated capacity points which are evenly spaced in both axial load and eccentricity angle.
  </LI>,
  <LI>
    <b>DCRs:</b> The DCRs reported are PM Vector DCRs, defined as the ratio of the length of the
    demand vector (in PMM space) to the length of a parallel vector beginning at the origin and
    continuing until it reaches the PMM surface.
  </LI>,
  <LI>
    <b>Capacity Point Search:</b> To calculate capacity points both for DCRs and for PMM diagrams,
    the program uses an iterative method in which the two parameters determining capacity (the
    neutral axis angle and neutral axis depth) are varied until the target capacity point is
    reached.
  </LI>,
  <LI>
    <b>P-M Diagrams:</b> These compare each load case (axial force and resultant moment) against the
    column's P-M capacity at the same direction of loading. These are generated by taking a cut of
    the PMM surface at a given eccentricity angle.
  </LI>,
  <P>
    More documentation is available at this calculation's README{" "}
    <a
      href="https://github.com/youandvern/efficalc/blob/main/examples/conc_col_pmm/README.md"
      target="_blank"
      rel="noopener noreferrer"
    >
      here
    </a>
    .
  </P>,
];

export default function ConcreteColumnPmm() {
  const instantResultsText =
    "Simply enter the column properties and load combinations to receive an immediate and accurate design strength evaluation in accordance with ACI 318-19. ";

  return (
    <Container maxWidth="xl">
      <HeadTags />

      <Stack marginY="1rem">
        <TitleSection title={CALC_TITLE} descriptionSection={Description} />

        <DesignIntro />
        <DesignSection calcName={CALC_NAME} />
        <br />

        <FeatureSection
          instantResultsText={instantResultsText}
          howItWorksListItems={howItWorksListItems}
        />

        <CloneOrCopyCode calcName={CALC_NAME} contentUnavailable />
        <RelatedCalcToolSection relatedTools={relatedTools} />
      </Stack>

      <Disclaimer />
    </Container>
  );
}
