import InfoIcon from "@mui/icons-material/Info";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";

export function csvToArray(text: string) {
  let p = "",
    row = [""],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if ("," === l && s) l = row[++i] = "";
    else if ("\n" === l && s) {
      if ("\r" === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = "")];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
}

export function tsvToArray(text: string) {
  let p = "",
    row = [""],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if ("\t" === l && s) l = row[++i] = "";
    else if ("\n" === l && s) {
      if ("\r" === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = "")];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
}

type Props = {
  open: boolean;
  onClose: () => void;
  addRows: (rows: string[][]) => void;
};

export default function InputTableNewRowsDialog({ open, onClose, addRows }: Props) {
  const [format, setFormat] = useState<"csv" | "tsv">("csv");
  const [multiRows, setMultiRows] = useState("");

  const handleChangeFormat = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setFormat(value === "csv" ? "csv" : "tsv");
  };

  const onAddMultiRows = () => {
    const parseRows = format === "csv" ? csvToArray : tsvToArray;
    const newRows = parseRows(multiRows?.trim() || "");
    addRows(newRows);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add multiple rows</DialogTitle>
      <DialogContent sx={{ gap: 2 }}>
        <DialogContentText gutterBottom>
          Insert multiple rows by pasting or typing values formatted as comma separated values (csv)
          or tab separated values (tsv).
        </DialogContentText>
        <DialogContentText variant="body2" gutterBottom>
          <InfoIcon fontSize="inherit" /> Note: cells copied from from Excel will typically paste in
          a tsv format.
        </DialogContentText>
        <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
          <FormLabel id="demo-radio-buttons-group-label">Input Format:</FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            row
            value={format}
            onChange={handleChangeFormat}
            name="radio-buttons-group"
          >
            <FormControlLabel value="csv" control={<Radio />} label="csv" />
            <FormControlLabel value="tsv" control={<Radio />} label="tsv" />
          </RadioGroup>
        </FormControl>

        <TextField
          id="add-multiple-rows"
          label="New Rows"
          multiline
          fullWidth
          minRows={3}
          sx={{ mt: 1 }}
          placeholder="e.g. 
          bar #1, 0.2, 12.5
          bar#2, 0.31, 11.25"
          value={multiRows}
          onChange={(e) => setMultiRows(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onAddMultiRows}>Add Rows</Button>
      </DialogActions>
    </Dialog>
  );
}
