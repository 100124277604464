import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet";
import { PublicCalcName } from "../../commonTypes/CalculationT";
import CloneOrCopyCode from "./common/CloneOrCopyCode";
import DesignSection from "./common/DesignSection";
import IntroSection from "./common/IntroSection";
import RelatedCalcToolSection, { RelatedTool } from "./common/RelatedCalcToolSection";
import { Disclaimer, Image, P, Subtitle } from "./common/copy-utils";

const CALC_TITLE = "Steel Beam Capacity Calculator";
export const CALC_NAME: PublicCalcName = "steel-beam-calculator";

function HeadTags() {
  return (
    <Helmet>
      <title>{CALC_TITLE} | efficalc</title>
      <meta
        name="description"
        content="Easily design your steel beam with our free online capacity calculator. Quickly and accurately calculate the design moment strength with a detailed calculation report. Try it now for free!"
      />
      <meta property="og:title" content="Steel Beam Capacity Calculator | efficalc" />
      <meta
        property="og:url"
        content="https://efficalc.com/public-calc/design/steel-beam-calculator"
      />
      <meta
        property="og:image"
        content="https://efficalc.com/images/public-calcs/wf_beam_design_bkg.jpg"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="A free online design tool to quickly and accurately calculate the design strength for a steel wide flange beam to AISC specifications."
      />
    </Helmet>
  );
}

function Introduction() {
  const Description = (
    <>
      <P>
        Easily design steel wide flange beams with this streamlined tool from efficalc. This
        powerful calculator provides a quick and verifiable way to determine the moment capacity of
        steel wide flange beams in accordance with the AISC 14th edition standards.
      </P>
      <Image
        src="/images/public-calcs/wf_beam_design.jpg"
        alt="Beam section and deflected profile"
      ></Image>
    </>
  );

  const instantResultsText =
    "Simply enter the load, span, and section size to receive an immediate and accurate design strength evaluation.";

  return (
    <IntroSection
      title={CALC_TITLE}
      descriptionSection={Description}
      instantResultsText={instantResultsText}
    />
  );
}

function DesignIntro() {
  return (
    <Container maxWidth="md">
      <Subtitle variant="h2" gutterBottom>
        Design your beam here:
      </Subtitle>
      <P>
        Use the interactive form below to design your steel beam. View or print the calculation
        report using the icons in the "Results" section.
      </P>
    </Container>
  );
}

const relatedTools: RelatedTool[] = [
  {
    calcName: "steel-beam-optimizer",
    text: "This automatically chooses an efficient wide flange beam section for your given moment demands and beam span with the same beam design calculations.",
  },
  {
    calcName: "truss-tool",
    text: "A free online design tool to quickly and accurately calculate axial forces in truss members and global truss deflection.",
  },
];

export default function SteelBeamFlexurePage() {
  return (
    <Container maxWidth="xl">
      <HeadTags />
      <Stack marginY="1rem">
        <Introduction />

        <DesignIntro />
        <DesignSection calcName={CALC_NAME} />
        <CloneOrCopyCode calcName={CALC_NAME} />
        <RelatedCalcToolSection relatedTools={relatedTools} />
      </Stack>

      <Disclaimer />
    </Container>
  );
}
