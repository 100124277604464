import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: 700,
  marginTop: "0.75em",
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.65rem",
  fontWeight: 700,
  marginTop: "0.75em",
}));

export const SubSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: 700,
  marginTop: "0.75em",
}));

export const P = styled(Typography)(() => ({
  marginBlock: "1em",
}));

export const LI = styled("li")(() => ({
  marginBlock: "1em",
}));

export const Image = styled("img")(({ theme }) => ({
  objectFit: "contain",
  maxWidth: "100%",
}));

export const Code = styled("code")(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));

export const Disclaimer = () => (
  <Typography fontSize="0.75rem">
    Disclaimer: This calculator is provided as a free tool to assist professionals and does not
    offer any warranties or guarantees regarding its use or the results. All calculations and
    engineering decisions must be reviewed and approved by a licensed professional engineer who will
    take responsibility for their application. Efficalc and its affiliates disclaim any
    responsibility for the accuracy or adequacy of any design resulting from the use of this
    calculator. Users agree to indemnify and hold harmless Efficalc from any claims, damages, or
    liabilities that may arise from the use of this calculator or its outputs. This calculator is
    not a substitute for professional judgment.
  </Typography>
);
