import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PublicCalcName } from "../../../commonTypes/CalculationT";
import Assumptions from "./Assumptions";
import InputOutputDesigner from "./InputOutputDesigner";

type Props = {
  calcName: PublicCalcName;
};

export default function DesignSection({ calcName }: Props) {
  const theme = useTheme();
  const wideScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Paper
        elevation={wideScreen ? 8 : 0}
        sx={{
          width: "fit-content",
          margin: "auto",
          padding: { xs: "0", md: "1rem" },
          paddingBottom: { xs: "0", md: "2rem" },
          borderWidth: { xs: 0, md: 2 },
          borderStyle: "solid",
          borderColor: (theme) => theme.palette.grey[500],
        }}
      >
        <Assumptions />
        <InputOutputDesigner calcName={calcName} />
      </Paper>
    </>
  );
}
